
import React, {Component} from 'react';
import '../App.css';
import error_img from '../404_final.png';
import { Alert } from 'antd';

class Error404 extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        
        return (

            <div>
                <Alert
                    message={<h1 className="alert-css">Check Your Mail for Valid Link</h1>}
                    type="warning"
                    
                    
                />
                <img src={error_img} className="error-image" alt={"page not found"}/>
                
            </div>
        );
    }
}

export default Error404;
