import React,{Component} from 'react';
import 'antd/dist/antd.css';
import {Comment, Avatar, Form, Button, Input, message} from 'antd';
import {getAllComments,postComment} from "../service/service";


const {TextArea} = Input;

const Editor = ({onChange, onSubmit, submitting, value}) => (
    <div>
        <Form.Item>
            <TextArea rows={4} onChange={onChange} value={value}/>
        </Form.Item>
        <Form.Item>
            <Button htmlType="submit" loading={submitting} onClick={onSubmit} type="primary">
                Send
            </Button>
        </Form.Item>
    </div>
);


class CommentsTab extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openEditor: true,
            parent: '',
            comments: [],
            submitting: false,
            status: false,
            openChildEditor: [false],
            parentId: null,
            childComment: '',
            childSubmitting: false
        }

    }

    componentDidMount() {
        this.getAllComments(this.props.petition_id)

    }

    reload = () =>{
        this.getAllComments(this.props.petition_id)
    }

    getAllComments(id) {
        getAllComments(id)
            .then((response) => {
                const res = response.json();
                const statusCode = response.status
                return Promise.all([statusCode, res]);
            })
            .then(([statusCode, res]) => {
                if (statusCode === 200) {
                    this.setState({
                        comments: res.results,
                        status: true
                    })
                } else {
                    message.error('Something Went Wrong')
                }
            })
            .catch(error => {
                console.log(error)
            });
    }


    handleChange = e => {
        this.setState({
            parent: e.target.value,
        });
    };

    handleSubmit = (i) => {
        if (!this.state.parent) {
            return;
        }

        this.setState({
            submitting: true,
        });
        const body={
            "comment_text":this.state.parent,
            "parent_comment":''

        }
        postComment(this.props.petition_id,body)
            .then((response) => {
                const statusCode = response.status;
                const res = response.json();
                return Promise.all([statusCode, res]);
            })
            .then(([status, res]) => {
                if(status === 201){
                    setTimeout(() => {
                        this.state.comments.push(res.result)
                        this.setState({
                            submitting: false,
                            parent: '',
                            openEditor: false
                        });
                    }, 1000);            

                }
                else{
                    message.error('Something Went Wrong')
                }
            })   
            .catch(error => {
                console.log(error)
            });

    }

    changeChildCommentOpenEditor = (i, comment_id) => {
        let arr = [false]
        arr[i] = true
        this.setState({openChildEditor: arr, parentId: comment_id})

    }

    changeCommentOpenEditor = (i) => {
        let arr = [false]
        arr[i] = true
        this.setState({openEditor: true})

    }

    handleChildChange = e => {
        this.setState({
            childComment: e.target.value,
        });
    };

    handleChildSubmit = (i, id) => {
        if (!this.state.childComment) {
            return;
        }

        this.setState({
            childSubmitting: true,
        });
        const body={
            "comment_text": this.state.childComment,
            "parent_comment":id,
            
        }
        postComment(this.props.petition_id,body)
            .then((response) => {

                const statusCode = response.status;
                const res = response.json();
                return Promise.all([statusCode, res]);
            })
            .then(([status, res]) => {
                if(status === 201){
                    setTimeout(() => {
                        this.setState({
                            childSubmitting: false,
                            childComment: '',
                            openChildEditor: [false],
                        });
                    }, 1000);
                    this.getAllComments(this.props.petition_id)

                }
                else{
                    message.error('Something Went Wrong')
                }
                
            })
            .catch(error => {
                console.log(error)
            });
    }

    render(){
        const name = localStorage.getItem('NAME');
        const {comments} = this.state;
        return(
            <div>
                <div className="chat-reload" onClick={this.reload}><b>Refresh</b></div>
            {this.state.status && 
                <div style={{padding:'15px'}}>
                    
                        <div>
                            {comments.length > 0 && comments.map((comment, i) => (
                                <Comment
                                    key={comment.id}
                                    actions={[<span key="comment-nested-reply-to"
                                    onClick={() => this.changeChildCommentOpenEditor(i, comment.parent_comment)}>Reply to</span>]}
                                    author={comment.user.employee_name}
                                    avatar={
                                        comment.user.employee_name.split(" ")[1] !== undefined ?
                                            <Avatar style={{backgroundColor:'#3881bf',color:'#fff'}}>{comment.user.employee_name.split(" ")[0].charAt(0).toUpperCase()+comment.user.employee_name.split(" ")[1].charAt(0).toUpperCase()}</Avatar>
                                            :
                                            <Avatar style={{backgroundColor:'#3881bf',color:'#fff'}}>{comment.user.employee_name.split(" ")[0].charAt(0).toUpperCase()}</Avatar>
                                            
                                    }
                                    datetime = {new Date(comment.created).toDateString() + ' ' + new Date(comment.created).toLocaleTimeString()}
                                    content={
                                        <p>
                                            {comment.comment_text}
                                        </p>
                                        }
                                >
                                    {comment.child_comment.length > 0 && comment.child_comment.map((childComment, j) =>
                                        <div>
                                            <Comment
                                                key={childComment.id}
                                                author={childComment.user.employee_name}
                                                datetime = {new Date(childComment.created).toDateString() + ' ' + new Date(childComment.created).toLocaleTimeString()}
                                                avatar={
                                                    childComment.user.employee_name.split(" ")[1] !== undefined ?
                                                        <Avatar style={{backgroundColor:'#3881bf',color:'#fff'}}>{childComment.user.employee_name.split(" ")[0].charAt(0).toUpperCase()+childComment.user.employee_name.split(" ")[1].charAt(0).toUpperCase()}</Avatar>
                                                        :
                                                        <Avatar style={{backgroundColor:'#3881bf',color:'#fff'}}>{childComment.user.employee_name.split(" ")[0].charAt(0).toUpperCase()}</Avatar>
                                                }
                                                content={
                                                    <p>
                                                        {childComment.comment_text}
                                                    </p>
                                                }
                                            />
                                        </div>
                                    )}
                                    {this.state.openChildEditor[i] &&
                                            <div>
                                                <Comment
                                                    avatar={
                                                        name.split(" ")[1] !== undefined ?
                                                            <Avatar style={{backgroundColor:'#3881bf',color:'#fff'}}>{name.split(" ")[0].charAt(0).toUpperCase()+name.split(" ")[1].charAt(0).toUpperCase()}</Avatar>
                                                            :
                                                            <Avatar style={{backgroundColor:'#3881bf',color:'#fff'}}>{name.split(" ")[0].charAt(0).toUpperCase()}</Avatar>
                                                        }
                                                    author={name}
                                                    content={this.state.openChildEditor[i]}
                                                />
                                                    <Editor
                                                        onChange={this.handleChildChange}
                                                        onSubmit={() => this.handleChildSubmit(i, comment.id)}
                                                        submitting={this.state.childSubmitting}
                                                        value={this.state.childComment}
                                                    />
                                            </div>
                                    }
                                </Comment>
                            ))}
                        </div>

                    
                        <div>
                            <Comment
                                    avatar={
                                        <Avatar style={{backgroundColor:'#3881bf',color:'#fff'}}>{name.split(" ")[0].charAt(0).toUpperCase()+name.split(" ")[1].charAt(0).toUpperCase()}</Avatar>
                                    }
                                    author={name}
                                    content={this.state.openEditor}
                                />
                                <Editor
                                    onChange={this.handleChange}
                                    onSubmit={() => this.handleSubmit("")}
                                    submitting={this.state.submitting}
                                    value={this.state.parent}
                                /> 
                        </div>
                    
                </div>
                } 
            </div>
        ) 
    }

}

export default CommentsTab;