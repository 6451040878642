import httpService from './httpService';
export function login(body) {                               //Beneficiary Login
    return httpService.post('consultant_petition/login/',body);
}
export function getDocType() {                               //Get all uploaded doc type
    return httpService.get('petition_docs/doc_types/');
}
export function getDocs(id) {                               //Get all uploaded docs
    return httpService.get('petition_docs/?petition_id='+id+'/');
}
export function uploadDoc(body) {                               //Get all uploaded docs
    return httpService.attachment('petition_docs/',body);
}
export function deleteDoc(id) {                               //Get all uploaded docs
    return httpService.delete('petition_docs/'+id+'/');
}
export function getAllComments(id){
    return httpService.get('petition_docs/'+id+'/comment/')
}

export function postComment(id,body){
    return httpService.post('petition_docs/'+id+'/comment/',body)
}

