import React, {Component} from 'react';
import '../App.css';
import {Upload, Button, message, Tabs, Spin,Drawer} from 'antd';

import {UploadOutlined, DeleteOutlined, CheckCircleOutlined, CommentOutlined,CloseCircleOutlined} from '@ant-design/icons';
import 'antd/dist/antd.css';
import {deleteDoc, getDocs, getDocType, uploadDoc} from "../service/service";
import consultadd_icon from "../consultadd_icon.png"
import CommentsTab from "./comments";

const {TabPane} = Tabs;

const PETITION_STATUS = [
    {value: 'new', name: 'New'},
    {value: 'rfe', name: 'RFE'},
    {value: 'denied', name: 'Denied'},
    {value: 'shipped', name: 'Shipped'},
    {value: 'approved', name: 'Approved'},
    {value: 'assigned', name: 'Assigned'},
    {value: 'reviewed', name: 'Reviewed'},
    {value: 'lca_filed', name: 'LCA Filed'},
    {value: 'print', name: 'Sent for Print'},
    {value: 'lca_approved', name: 'LCA Approved'},
    {value: 'under_review', name: 'Under Review'},
    {value: 'rfe_responded', name: 'RFE Docs Sent'},
    {value: 'doc_acknowledged', name: 'Docs Acknowledged'},
    {value: 'doc_request_sent', name: 'Document Request Sent'},
    {value: 'rfe_responded', name: 'RFE Responded'},
    {value: 'rfe_doc_acknowledged', name: 'RFE Document Acknowledeged'}

]

class FileUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fileList: [],
            attachments: [],
            categories: [
                'Profile and Academic',
                'Passport and Visa',
                'Immigration History',
                'Employment',
                'Employer Relationship',
                'Other'
            ],
            dataSource: [],
            status: false,
            flag: false,
            loading: false,
            petition: '',
            chatDrawer:false
        };
    }

    componentDidMount() {

        const petition = localStorage.getItem("PETITION");
        const token = localStorage.getItem("TOKEN");
        if (!token) {
            this.props.history.push('/error404')
        } else {
            this.setState({
                petition: petition
            }, () => {
                this.getDocTypes()
                this.getDocs(petition)
            })

        }

    }

    handleClose = () => {
        this.setState({
            chatDrawer:false
        })
    }

    getDocs = (petition) => {

        getDocs(petition)
            .then((response) => {

                const statusCode = response.status;
                const res = response.json();
                return Promise.all([statusCode, res]);
            })
            .then(([status, res]) => {
                if (status === 401) {
                    localStorage.removeItem('PETITION');
                    localStorage.removeItem('TOKEN');
                    localStorage.removeItem('LEGAL');
                    this.props.history.push('/')

                } else {
                    this.setState({
                        attachments: res.results,
                        status: true
                    })
                }

            })
            .catch(error => {
                console.log(error)
            });
    }
    getDocTypes = () => {
        getDocType()
            .then((response) => {

                const statusCode = response.status;
                const res = response.json();
                return Promise.all([statusCode, res]);
            })
            .then(([status, res]) => {
                if (status === 401) {
                    localStorage.removeItem('PETITION');
                    localStorage.removeItem('TOKEN');
                    localStorage.removeItem('LEGAL');
                    this.props.history.push('/')

                } else {
                    this.setState({
                        dataSource: res.results,
                        flag: true
                    })
                }

            })
            .catch(error => {
                console.log(error)
            });
    }

    handleChange = (info, type) => {
        let fileList = this.state.fileList;
        fileList.push(info.file)
        this.setState({
            fileList
        });


    };
    onSubmit = (type) => {
        this.setState({
            loading: true
        })
        let formData = new FormData();
        const petition = localStorage.getItem("PETITION")

        formData.append("petition", petition);
        formData.append("file_type", type);

        this.state.fileList.forEach(file => {
            formData.append('file', file.originFileObj);
        })
        if (this.state.fileList.length !== 0) {
            uploadDoc(formData)
                .then((response) => {

                    const statusCode = response.status;
                    const res = response.json();
                    return Promise.all([statusCode, res]);
                })
                .then(([status, res]) => {
                    if (status === 401) {
                        localStorage.removeItem('PETITION');
                        localStorage.removeItem('TOKEN');
                        this.props.history.push('/?email=')

                    } else {
                        message.success("Files uploaded.")
                        let attachments = this.state.attachments;
                        attachments.push(res.result)
                        this.setState({
                            attachments: res.result,
                            fileList: [],
                            loading: false,
                        })
                    }

                })
                .catch(error => {
                    console.log(error)
                });
        } else {
            message.error("No file selected")
        }

    }

    beforeUpload(file) {
        const isJpgOrPngOrPdf = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf';
        if (!isJpgOrPngOrPdf) {
            message.error('You can only upload JPG/PNG/PDF file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 100;
        if (!isLt2M) {
            message.error('Files must smaller than 100MB!');
        }
        return isJpgOrPngOrPdf && isLt2M;
    }

    customRequest = (file, type) => {

    }
    onDelete = (id) => {
        deleteDoc(id)
            .then((response) => {

                const statusCode = response.status;
                if (statusCode === 400) {
                    message.error("Something Went Wrong")
                }
                if (statusCode === 204) {
                    message.success("Deleted Successfully")
                    this.getDocs(this.state.petition)
                }
            })
            .catch(error => {
                console.log(error)
            });
    }
    onCancel = (file) => {
        let fileList = this.state.fileList;
        let index = fileList.indexOf(file);
        if (index > -1) {
            fileList.splice(index, 1);
        }
        this.setState(fileList)
    }
    logout = () => {
        localStorage.removeItem("TOKEN")
        localStorage.removeItem("PETITION")
        localStorage.removeItem("LEGAL")
        this.props.history.push("/?email=")
    }


    render() {

        const ename = localStorage.getItem("NAME")
        const email = localStorage.getItem("EMAIL")
        const legal = JSON.parse(localStorage.getItem("LEGAL"))
        let status = PETITION_STATUS.find((pstatus)=> pstatus.value === legal.petition_status)
        let petition = localStorage.getItem("PETITION");

        return (
            this.state.status && this.state.flag ?
                <div className="upload-section">
                    <div className="header">
                        <div style={{position: 'absolute', right: 20, cursor: 'pointer'}} onClick={this.logout}>
                            <h3 style={{paddingTop: '20px'}}><Button style={{color:' #005ead',fontWeight:'700'}}>Logout</Button></h3>
                        </div>
                        <img className="caicon" src={consultadd_icon} alt={"consultadd icon"}/>


                        <div className="col-md-12">
                            <ul className="tophead-new">
                                <li><span>Hello {ename}</span> <br/> <span>{" "} ({email}) {" "}</span></li>
                                <li><span>Your petition Status is : </span> <br /> <span style={{fontWeight: '800', padding:"2px", backgroundColor: 'white',color:'darkgreen',borderRadius:'7px',fontSize:"14px"}}> {status.name}</span></li>
                                <li><span>Your Legal Contact is : </span> <br/> <span>{legal.legal_poc}{"("}{legal.legal_email}{")"}</span></li>
                                <li><span onClick={()=>this.setState({chatDrawer:true})} style={{marginRight:"5px", fontSize: 'x-large',cursor:'pointer',}}><CommentOutlined /><span style={{marginLeft:'4px',fontSize:'18px'}}>Q/A</span> </span></li>
                            </ul>

                        </div>
                        {
                            this.state.chatDrawer && (
                                <Drawer
                                    title="Questions And Comments"
                                    onClose={this.handleClose}
                                    visible={this.state.chatDrawer}
                                    style={{marginTop:'130px',marginBottom:'50px',marginRight:'50px',height:'600px'}}
                                    width={450}
                                    maskClosable={false}
                                    mask={false}

                                >
                                    <CommentsTab petition_id={petition}/>
                                </Drawer>
                            )
                        }


                        <Tabs style={{marginLeft: '20px', marginTop: '20px'}}>

                            {
                                this.state.categories.map((category, catIndex) =>

                                    <TabPane tab={category} key={catIndex}>

                                        <Spin spinning={this.state.loading}
                                              tip="Please wait your data is being stored..">
                                            <Tabs defaultActiveKey="1" tabPosition="left">
                                                {this.state.dataSource[category].map((docType, i) =>

                                                    <TabPane tab={docType.value} key={(i + 1).toString()}>
                                                        <div style={{marginLeft: '10px'}}>
                                                            <h1 style={{textAlign: 'left'}}>{docType.value}</h1>
                                                            <div className="">
                                                             <span style={{
                                                                 float: 'left',
                                                                 width: '100%',
                                                                 marginBottom: '20px'
                                                             }}>
                                                                {
                                                                    docType.status === "not_uploaded" ?
                                                                        <span
                                                                            style={{color: '#aa9f31'}}>
                                                                                                <CheckCircleOutlined
                                                                                                    style={{fontSize: 20}}/>
                                                                                                <span
                                                                                                    className="verified-icon"
                                                                                                    style={{
                                                                                                        color: '#aa9f31',
                                                                                                        marginLeft: '3px'
                                                                                                    }}>
                                                                                                       Not Uploaded
                                                                                                </span>
                                                                                            </span> :

                                                                        docType.status === "in_review" ?
                                                                            <span
                                                                                style={{color: '#aa9f31'}}>
                                                                                                <CheckCircleOutlined
                                                                                                    style={{fontSize: 20}}/>
                                                                                                <span
                                                                                                    className="verified-icon"
                                                                                                    style={{
                                                                                                        color: '#aa9f31',
                                                                                                        marginLeft: '3px'
                                                                                                    }}>
                                                                                                        In Review
                                                                                                </span>
                                                                                            </span> :
                                                                            docType.status === "accepted" ?

                                                                                <span
                                                                                    style={{color: 'green'}}>
                                                                                                <CheckCircleOutlined
                                                                                                    style={{fontSize: 20}}/>
                                                                                                <span
                                                                                                    className="verified-icon"
                                                                                                    style={{
                                                                                                        color: 'green',
                                                                                                        marginLeft: '3px'
                                                                                                    }}>
                                                                                                    Accepted
                                                                                                </span>
                                                                                            </span> :
                                                                                docType.status === "rejected" ?
                                                                                    <span
                                                                                        style={{color: 'red'}}><CloseCircleOutlined
                                                                                        style={{fontSize: 20}}/><span
                                                                                        style={{
                                                                                            color: 'red',
                                                                                            marginLeft: '3px'
                                                                                        }}
                                                                                        className="verified-icon">Rejected</span>
                                                                                        {docType.remark === "" || docType.remark === null ? null : ":"}
                                                                                        <h4 style={{
                                                                                            color: 'red',
                                                                                            marginLeft: '3px'
                                                                                        }}>{docType.remark}</h4></span>

                                                                                    : null

                                                                }
                                                                                </span>
                                                            </div>
                                                            <br/>
                                                            {
                                                                this.state.attachments.length !== 0 && this.state.attachments.map((ele, index) =>
                                                                    docType.name === ele.doc_type_name ?
                                                                        <div style={{
                                                                            height: '40px',
                                                                            float: 'left',
                                                                            marginRight: '10px',
                                                                            width: '34%',
                                                                            paddingTop: '8px',
                                                                            marginBottom: '10px',
                                                                            textAlign: 'center',
                                                                            backgroundColor: '#f0f0f0'
                                                                        }}>
                                                                            {ele.file_name !== "" ?
                                                                                <span>{ele.file_name}</span> : ""}

                                                                            {!ele.verified && ele.verified !== null ?
                                                                                <DeleteOutlined className="deleteicon"
                                                                                                style={{
                                                                                                    color: 'red',
                                                                                                    fontSize: 24
                                                                                                }}
                                                                                                onClick={() => this.onDelete(ele.id)}/>
                                                                                : null
                                                                            }
                                                                        </div> :
                                                                        null
                                                                )}

                                                            <div>
                                                                {this.state.fileList.map((file, index) =>
                                                                    <div style={{
                                                                        height: '40px',
                                                                        float: 'left',
                                                                        marginRight: '10px',
                                                                        width: '34%',
                                                                        paddingTop: '8px',
                                                                        marginBottom: '10px',
                                                                        textAlign: 'center',
                                                                        backgroundColor: '#f0f0f0'
                                                                    }}>
                                                                        <span>{file.name}</span>
                                                                        <CloseCircleOutlined className="deleteicon"
                                                                                             style={{
                                                                                                 color: 'red',
                                                                                                 fontSize: 24
                                                                                             }}
                                                                                             onClick={() => this.onCancel(file)}/>
                                                                    </div>
                                                                )}


                                                            </div>
                                                            {

                                                                (docType.status !== "accepted") ?

                                                                    <div className="button-placement">
                                                                        <Upload
                                                                            onChange={(f) => this.handleChange(f, docType.id)}
                                                                            multiple={true}
                                                                            beforeUpload={this.beforeUpload}
                                                                            customRequest={(f) => this.customRequest(f, docType.id)}
                                                                            defaultFileList={this.state.attachments}
                                                                            showUploadList={false}
                                                                        >
                                                                            <Button>
                                                                                <UploadOutlined/> Upload
                                                                            </Button>
                                                                        </Upload>

                                                                        <Button
                                                                            onClick={() => this.onSubmit(docType.id)}
                                                                            style={{
                                                                                backgroundColor: 'green',
                                                                                color: 'white',
                                                                                marginLeft: '50px'
                                                                            }}>Submit</Button>
                                                                    </div>
                                                                    : null

                                                            }
                                                        </div>
                                                    </TabPane>
                                                )}
                                            </Tabs>
                                        </Spin>
                                    </TabPane>
                                )
                            }
                        </Tabs>


                    </div>
                </div>
                : <div>
                    Loading
                </div>
        );
    }
}

export default FileUpload;

