import React from 'react';
import ReactDOM from 'react-dom';
import "./index.css";
import { Route, Switch, HashRouter as Router } from "react-router-dom";
import * as serviceWorker from './serviceWorker';
import Login from "./components/login";
import FileUpload from "./components/fileUpload";
import Error404 from "./components/error404";


const routing = (
    <Router basename="/">
        <Switch>
            <Route path="/?email=" exact component={Login} />
            <Route path="/" exact component={Login} />
            <Route path="/error404" exact component={Error404} />
            <Route path="/fileUpload" component={FileUpload} />
        </Switch>
    </Router>
);
ReactDOM.render(routing,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
