import React, {Component} from 'react';
import '../App.css';
import {Input, message, Spin} from "antd";
import 'antd/dist/antd.css';
import {login} from "../service/service";

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            spinLoading: false,
            username: 'Ayushi static',
            pin: '',
            token: ''
        };
    }

    componentDidMount() {
        const token =localStorage.getItem("TOKEN");
        if(token){
            this.props.history.replace("/fileUpload")
        }
        else{

            if (this.props.location.search !== "") {
                this.setState({
                    username: this.props.location.search.split("=")[1]
                })
            } else {
                this.props.history.replace("/error404")
            }
        }



    }

    login = () => {
        const body = {
            'email': this.state.username,
            'password': this.state.pin
        }
        
        login(body)
            .then((response) => {

                const statusCode = response.status;
                const res = response.json();
                return Promise.all([statusCode, res]);
            })
            .then(([status, res]) => {
                if (status !== 202) {
                    if(status === 400){
                        message.error('Invalid email id or password')
                    }
                    else{
                        message.error("Something went wrong.")
                    }
                } else {

                    const legal = {
                        legal_email: res.result.petition.assigned_to.email,
                        legal_poc: res.result.petition.assigned_to.employee_name,
                        petition_status:res.result.petition.status
                    }

                    localStorage.setItem("TOKEN", res.result.token)
                    localStorage.setItem("PETITION", res.result.petition.id)
                    localStorage.setItem("NAME",res.result.name )
                    localStorage.setItem("EMAIL",res.result.email )
                    localStorage.setItem("LEGAL",JSON.stringify(legal))
                    this.props.history.replace("/fileUpload")
                }

            })
            .catch(error => {
                console.log(error)
            });

    }
    handleUserInput = (e) => {
        e.preventDefault()
        const value = e.target.value;
        this.setState({pin: value});


    }
    handleKeyPress = (event) => {

        if (event.key === 'Enter') {
            event.preventDefault()
            this.login();
        }

    }

    render() {
        return (
            
            <Spin spinning={this.state.spinLoading} tip="Logging in....">

                <div className="login-section">
                    <form>
                        <div style={{ textAlign: 'center', fontSize: '20px' }}>
                            <h3>Login</h3>
                            
                        </div>
                        <ul>

                            <li>
                                <div className="user-id">
                                    <label>Email</label>
                                    <span>{this.state.username}</span>
                                </div>
                            </li>

                            <li>
                                <label htmlFor="pin">pin</label>
                                <span><div className="login-password">

                                    <Input.Password name="pin" value={this.state.pin}
                                        placeholder="pin"
                                        onKeyDown={this.handleKeyPress}
                                        onChange={this.handleUserInput} />
                                </div></span>
                            </li>
                        </ul>

                        <button type="button"
                                onClick={(event) => this.login()}>Next
                        </button>

                    </form>
                </div>
            </Spin>
            
        );
    }
}

export default Login;
